import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Navibar from './navibar'
import './style.scss'

interface Props {
  title: string
  location: Location
}

const NavibarWrapper: React.FC<Props> = ({ location, title }: Props) => {
  return (
    <StaticQuery
      query={graphql`
        query NavibarQuery {
          baby: file(name: { eq: "baby-icon" }) {
            childImageSharp {
              fixed(width: 30, height: 30) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <Navibar data={data} title={title} location={location} />
        </>
      )}
    />
  )
}

export default NavibarWrapper
