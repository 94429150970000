import React from 'react'
import { Link } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { StaticQuery } from 'gatsby'
import './style.scss'

interface Props {
  data: StaticQuery
  title: string
  location: Location
}

export const Navibar: React.FC<Props> = ({ data, location, title }: Props) => {
  const baby = data.baby?.childImageSharp?.fixed
  return (
    <nav className="navbar navbar-expand-sm navbar-dark bg-primary">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <span>
            <Img
              className="mb-0 mr-2 d-inline-block align-top"
              fixed={baby as FixedObject}
              loading="lazy"
            />
          </span>
          <span className="h2">{title}</span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navi"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navi">
          <div className="mr-auto"></div>
          <ul className="navbar-nav">
            <li className={location.pathname === '/' ? 'nav-item active' : 'nav-item'}>
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className={location.pathname === '/registry/' ? 'nav-item active' : 'nav-item'}>
              <Link to="/registry/" className="nav-link">
                Registry
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
export default Navibar
